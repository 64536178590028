<template>
  <v-dialog v-model="dialog" hide-overlay width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn raised text tile v-bind="attrs" v-on="on">Recuperar Senha</v-btn>
    </template>
    <v-card>
      <v-card-title class="primary py-4 title white--text">
        Recuperar Senha
      </v-card-title>
      <v-container grid-list-sm class="pa-4">
        <!-- <v-spacer class="pt-2"></v-spacer> -->
        <v-layout row wrap>
          <v-flex xs12 align-center justify-space-between>
            <v-layout align-center>
              <v-avatar size="40px" class="mr-3">
                <v-icon class="grey lighten-2" dark>mdi-email</v-icon>
              </v-avatar>
              <v-text-field
                label="E-mail"
                persistent-hint
                placeholder=""
                v-model="email"
              ></v-text-field>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>
      <v-card-actions>
        <!-- <v-btn flat color="primary">More</v-btn> -->
        <v-spacer></v-spacer>
        <v-btn text  @click="dialog = false">Cancelar</v-btn>
        <v-btn raised color="primary" @click="recuperar()">Recuperar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import firebase from "firebase/app";
import toastr from "toastr";
export default {
  name: "RecuperarSenhaModal",
  data() {
    return {
      dialog: false,
    };
  },
  props:{
    email: String
  },
  methods: {
    fechar() {
      this.dialog = false;
    },
    recuperar: function () {
      var auth = firebase.auth();
      var emailAddress = this.email;

      auth
        .sendPasswordResetEmail(emailAddress)
        .then(function () {
          // Email sent.
          toastr.success(
            "Um e-mail de recuperação foi enviado para o endereço informado!",
            "Confabular"
          );
          window.fechar();
        })
        .catch(function (error) {
          // An error happened.
          console.log(error);
          toastr.error(
            "Não foi possível encontrar o e-mail informado, ou ocorreu algum erro ao processar a recuperação.",
            "UCP"
          );
          toastr.error(error.message, "UCP");
        });
    },
  },
  created() {
    window.fechar = this.fechar;
  },
};
</script>